<template>
  <div class="wrapper">
    <b-row class="sliderRow">
      <b-col class="sliderCol">
        <b-carousel
        id="carousel-entrepreneurs"
        fade
        indicators
        controls
        :interval="4000"
        style="text-shadow: 1px 1px 2px #333;"
        img-width="1024"
        img-height="350"
        v-model="slide"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        >
        <b-carousel-slide
            img-src="/images/slides/b4.jpg"
        />
        <b-carousel-slide 
            img-src="/images/slides/b5.jpg" 
        />
        </b-carousel>
      </b-col>
    </b-row>
    <section>
      <div class="container">
        <b-row class="content">
            <b-col cols="12" class="col-lg-6">
              <ul>
                <li>
                  <router-link to="/emprendedores/asesoramiento">
                    <font-awesome-icon :icon="['fas', 'handshake']" class="icon-list"/>
                    <p>ASESORAMIENTO</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/incubadora">
                    <font-awesome-icon :icon="['fas', 'suitcase']" class="icon-list"/>
                    <p>INCUBADORA</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/financiacion">
                    <font-awesome-icon :icon="['fas', 'chart-line']" class="icon-list"/>
                    <p>FINANCÍON</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/emprendemos-contigo">
                    <font-awesome-icon :icon="['fas', 'lightbulb']" class="icon-list"/>
                    <p>EMPRENDEMOS CONTIGO</p>
                  </router-link>
                </li>
              </ul>
            </b-col>
            <b-col cols="12" class="col-lg-6">
              <ul>
                <li>
                  <router-link to="/emprendedores/equipo">
                    <font-awesome-icon :icon="['fas', 'users']" class="icon-list"/>
                    <p>NUESTRO EQUIPO</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/programa-aceleracion-proyectos">
                    <font-awesome-icon :icon="['fas', 'users-cog']" class="icon-list"/>
                    <p>PROGRAMA DE ACELERACIÓN</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/recursos">
                    <font-awesome-icon :icon="['fas', 'download']" class="icon-list"/>
                    <p>RECURSOS</p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/emprendedores/colaboradores">
                    <font-awesome-icon :icon="['fas', 'chalkboard-teacher']" class="icon-list"/>
                    <p>COLABORADORES</p>
                  </router-link>
                </li>
              </ul>
            </b-col>
        </b-row>
      </div>
    </section>
    <section class="bottom">
      <div class="background">
        <div class="container">
          <div id="text">
            <h1>CLUB DE EMPRENDEDORES</h1> 
            <h2>un lugar para empezar el éxito</h2>
          </div>
          <div id="btn-red" @click="$router.push('/emprendedores/incubadora')">
            <span id="one">¿Tienes un proyecto empresarial?</span>
            <br>
            <span id="two">
              Aquí tienes tu espacio
              <font-awesome-icon :icon="['fas', 'arrow-right']"/>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      slide: 0,
      sliding: null,
    }
  },
  metaInfo() {
    return {
      title: this.$t('emprendedores.emprendedores'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.emprendedores') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.emprendedores') + ' - Club de Ejecutivos' },
      ]
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  }
}
</script>

<style scoped lang="scss">
.sliderRow {
  width: 100%;
  margin: 0;
}

.sliderCol {
  padding: 0;
}

.content {
  padding: 50px 0;
  text-align: left;

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 1.9rem;
      text-transform: uppercase;
      line-height: 5rem;
      border-bottom: 3px solid #009090;
      cursor: pointer;

      .icon-list {
          width: 50px;
      }

      p {
        display: inline-block;
      }

      a {
        color: #2c3e50;
      }
    }

    li:hover p {
        text-decoration: underline;
    }

    li:hover .icon-list {
        opacity: 0.8;
    }
  }
}

@media screen and (max-width: 767px) {
    .content {
        ul {
            li {
                text-align: center;
                line-height: 3rem;
                padding: 20px 0;

                p {
                    display: block;
                }
            }
        }
    }

    section.bottom {
      .container {
        #text {
          h1 {
            font-size: 2rem !important;
          }
          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }

    #carousel-entrepreneurs {
        display: none;
    }
}

section.bottom {
    text-align: left; 

    .container {
        padding: 50px 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        #btn-red {
            margin: auto 0;
            background: #009090;
            color: white;
            padding: 20px 40px;
            font-size: 1.4rem;
            cursor: pointer;

            span#one {
                font-weight: 400;
            }

            span#two {
                font-weight: 300;
            }
        }
 
        #btn-red:hover {
            span#one,
            span#two {
                text-decoration: underline;
            }
        }
        

        #text {
            h1 {
                font-size: 3rem;
            }
            h2 {
                font-weight: 400;
            }
        }
    }

    .background {
        background: #e5e5e5;
    }
}
</style>